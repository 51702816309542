
/**
 * Definicao de parametros de configuracao globais do app.
 */
export class AppConfig {

    readonly environment = process.env.NODE_ENV as 'production' | 'development'
    readonly apiBaseUrl: string

    /** Instancia unica da classe. */
    private static instance: AppConfig

    private constructor() {

        console.log('error', this.environment)

        switch (this.environment) {

            case 'development':
                this.apiBaseUrl = 'http://localhost:3001'
                break

            case 'production':
                // this.apiBaseUrl = 'https://api.vx.med.br' // endpoint de producao para api de download de exames.
                // this.apiBaseUrl = 'https://api-3.vx.med.br' // endpoint de producao para api padrao.
                this.apiBaseUrl = 'https://api-dev.vx.med.br' // endpoint de homologacao.
                break

            default:
                throw new Error('Ambiente não configurado!')
        }
    }

    /** Gera & retorna instancia unica da classe. */
    static getInstance(): AppConfig {
        if (!this.instance)
            this.instance = new AppConfig()
        return this.instance
    }
}
